import '../stylesheets/website.sass.scss'

import { initializeRails } from '@/shared/init'

initializeRails()

// Import third-party libraries
import "bootstrap/dist/js/bootstrap.bundle"
import "typed.js"
import Swiper from "swiper"
import AOS from "aos"
import SmoothScroll from "smooth-scroll"

// Initialize features when DOM is loaded
document.addEventListener("DOMContentLoaded", function() {
  // Initialize smooth scroll
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    easing: 'easeInOutQuad'
  });

  // Initialize AOS (Animate on Scroll)
  AOS.init({
    offset: 0,
    once: true,
    easing: 'ease-out'
  });

  // Initialize Swiper
  var swiper = new Swiper(".swiper-container", {
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 20
      }
    }
  });

  // Muse Switch Toggle
  var elem = document.getElementById("switch-content");
  if (elem) {
    elem.addEventListener('click', function() {
      elem.classList.toggle("switch-hide");
    });
  }
});