import * as Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import * as Turbolinks from "turbolinks"

export function initializeRails() {
    if (!window.Rails &&  !window.Turbolinks) {
        Rails.start()
        Turbolinks.start()
        ActiveStorage.start()
    }
}